import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import hovenier from "../images/hovenier.jpg"
import restaurant from "../images/restaurant.jpg"
import kapper from "../images/kapper.jpg"
import advocatenkantoor from "../images/advocatenkantoor.jpg"
import installatiebedrijf from "../images/installatiebedrijf.jpg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"
import logo from "../images/logo.png"
import Emoji from "../components/emoji"
import nicoHuntink from "../images/nico-huntink.jpg"
import emileTerHuerne from "../images/emile-ter-huerne.jpg"

const BusinessItem = ({ linkTo, backgroundImage, title }) => (
  <Link
    to={linkTo}
    className="block rounded-sm relative h-48 bg-cover bg-no-repeat cursor-pointer group"
    style={{ backgroundImage: `url('${backgroundImage}')` }}
  >
    <header className="absolute bottom-0 left-0 right-0 p-3 bg-gray-900-80% group-hover:bg-black-80% rounded-b-sm flex justify-between items-center text-gray-300 group-hover:text-white">
      <h1>{title}</h1>
      <FontAwesomeIcon
        icon={faArrowRight}
        className="invisible group-hover:visible"
      />
    </header>
  </Link>
)

const Testimonial = ({ name, title, avatar, site, url, children }) => (
  <section className="bg-gray-300 rounded-sm p-4 h-full flex flex-col">
    <main className="p-2 mb-4 flex-grow">
      <p className="text-lg">"{children}"</p>
    </main>
    <footer className="flex -m-2 items-center">
      <aside className="p-2 flex-shrink-0">
        <img src={avatar} alt={name} className="rounded-full w-10 h-10" />
      </aside>
      <main className="p-2">
        <header className="flex">
          <h1 className="font-bold">{name}</h1> <span className="mx-2">·</span>{" "}
          <a href={url} className="link">
            {site}
          </a>
        </header>
        <p className="text-gray-700">{title}</p>
      </main>
    </footer>
  </section>
)

export default function Index() {
  return (
    <Layout>
      <Seo title="Home" />
      <div className="container py-8">
        <h1 className="h1">
          Op zoek naar een professionele website voor uw bedrijf?
        </h1>
        <p className="paragraph">
          Laat een professionele website voor uw onderneming op maat ontwerpen
          door Thomas' Webdesign.
        </p>

        <div className="row mt-2 md:mt-4">
          <div className="column sm:w-1/2 md:w-1/3">
            <BusinessItem
              linkTo="/diensten"
              backgroundImage={hovenier}
              title="Hoveniersbedrijf"
            />
          </div>

          <div className="column sm:w-1/2 md:w-1/3">
            <BusinessItem
              linkTo="/diensten"
              backgroundImage={restaurant}
              title="Restaurant"
            />
          </div>

          <div className="column sm:w-1/2 md:w-1/3">
            <BusinessItem
              linkTo="/diensten"
              backgroundImage={kapper}
              title="Kapper of beautysalon"
            />
          </div>

          <div className="column sm:w-1/2 md:w-1/3">
            <BusinessItem
              linkTo="/diensten"
              backgroundImage={advocatenkantoor}
              title="Advocatenkantoor"
            />
          </div>

          <div className="column sm:w-1/2 md:w-1/3">
            <BusinessItem
              linkTo="/diensten"
              backgroundImage={installatiebedrijf}
              title="Installatiebedrijf"
            />
          </div>

          <div className="column sm:w-1/2 md:w-1/3 flex">
            <div className="rounded-sm h-48 bg-gray-300 p-6 text-center flex flex-col justify-center">
              <h3 className="h3">Heeft u een ander soort organisatie?</h3>
              <p className="text-gray-700 text-sm">
                Geen probleem. Neem contact met ons op en we kijken samen naar
                de mogellijkheden.
              </p>
            </div>
          </div>
        </div>

        <section className="mt-8">
          <h1 className="h1">Dit is wat klanten zeggen over ons</h1>
          <p className="paragraph">
            Laat een professionele website voor uw onderneming op maat ontwerpen
            door Thomas' Webdesign.
          </p>
          <div className="row mt-0">
            <div className="column w-full sm:w-1/2">
              <Testimonial
                name="Nico Huntink"
                title="Organisator Ajax-B Supermanager"
                site="ajaxb-supermanager.nl"
                url="http://ajaxb-supermanager.nl"
                avatar={nicoHuntink}
              >
                De site ziet er{" "}
                <em className="highlight">zeer professioneel</em> uit. Ik vind
                het knap in elkaar zitten. Bedankt voor het vele werk tot nu
                toe.
              </Testimonial>
            </div>
            <div className="column w-full sm:w-1/2">
              <Testimonial
                name="Emile ter Huerne"
                site="emileterhuerne.nl"
                url="https://emileterhuerne.nl"
                title="Directeur Hoveniersbedrijf ter Huerne"
                avatar={emileTerHuerne}
              >
                Thomas, mooi dat de nieuwe site live staat. Je hebt een{" "}
                <em className="highlight">mooi stukje werk geleverd!</em>{" "}
                <Emoji text="👍" />
              </Testimonial>
            </div>
          </div>
        </section>

        <section className="mt-8">
          <h1 className="h1">Laatste nieuws &amp; updates</h1>

          <div className="flex -m-2 items-center mt-2">
            <aside className="p-2 flex-shrink-0">
              <img src={logo} alt="Logo" className="w-10 h-10 rounded-full" />
            </aside>
            <main className="p-2">
              <p className="text text-lg">
                Nieuwe website van{" "}
                <a href="https://pensionvredenberg.nl/" className="link">
                  Pension Vredenberg
                </a>{" "}
                staat ook live! <Emoji text="💪" />.
              </p>
              <p className="text-gray-700 text-sm">Thomas' Webdesign</p>
            </main>
          </div>
          <div className="flex -m-2 items-center mt-2">
            <aside className="p-2 flex-shrink-0">
              <img src={logo} alt="Logo" className="w-10 h-10 rounded-full" />
            </aside>
            <main className="p-2">
              <p className="text text-lg">
                Vandaag een begin gemaakt aan de nieuwe website van Pension
                Vredenberg <Emoji text="🎨" />.
              </p>
              <p className="text-gray-700 text-sm">Thomas' Webdesign</p>
            </main>
          </div>
          <div className="flex -m-2 items-center mt-2">
            <aside className="p-2 flex-shrink-0">
              <img src={logo} alt="Logo" className="w-10 h-10 rounded-full" />
            </aside>
            <main className="p-2">
              <p className="text text-lg">
                Nieuwe website van{" "}
                <a href="http://emile-ter-huerne.netlify.com/" className="link">
                  Hoveniersbedrijf ter Huerne
                </a>{" "}
                staat sinds vandaag live <Emoji text="🎉" />.
              </p>
              <p className="text-gray-700 text-sm">Thomas' Webdesign</p>
            </main>
          </div>
          <div className="flex -m-2 items-center mt-6">
            <aside className="p-2 flex-shrink-0">
              <img src={logo} alt="Logo" className="w-10 h-10 rounded-full" />
            </aside>
            <main className="p-2">
              <p className="text text-lg">
                Vandaag weer aan website van Hoveniersbedrijf ter Huerne
                gewerkt.
              </p>
              <p className="text-gray-700 text-sm">Thomas' Webdesign</p>
            </main>
          </div>
          <div className="flex -m-2 items-center mt-6">
            <aside className="p-2 flex-shrink-0">
              <img src={logo} alt="Logo" className="w-10 h-10 rounded-full" />
            </aside>
            <main className="p-2">
              <p className="text text-lg">
                Goed gesprek gehad vanmmidag bij Emile van Hoveniersbedrijf ter
                Huerne over zijn nieuwe website <Emoji text="👍" />.
              </p>
              <p className="text-gray-700 text-sm">Thomas' Webdesign</p>
            </main>
          </div>
        </section>
      </div>
    </Layout>
  )
}
